<template>
  <div>
    <NuxtLayout name="auth">
      <h1 class="mt-6 text-3xl font-extrabold text-center text-cblack">
        {{ $t('login.heading') }}
      </h1>
      <p class="max-w-md mx-auto mt-6 text-sm text-center text-gray-800">
        {{ $t('login.description') }}
      </p>

      <div
        v-if="isInvalidCredentials"
        class="flex items-center justify-center mt-4 text-sm text-cred"
      >
        <Icon name="heroicons:exclamation-circle" size="24" class="mr-2" />

        <span>{{ $t('login.error') }}</span>
      </div>
      <form class="px-4 mt-8 space-y-6" @submit.prevent="handleLogin">
        <div>
          <VInput
            :label="$t('mail')"
            name="email"
            type="email"
            autocomplete="email"
            class="mb-6"
          />
          <VInput
            :label="$t('password')"
            name="password"
            type="password"
            autocomplete="current-password"
            is-show-password-button-enabled
          />
          <div class="flex justify-end">
            <VButton
              to="/auth/password/recovery"
              aria-label="Passwort zurücksetzen"
              :variant="Variant.link"
              :size="Size.xs"
            >
              {{ $t('login.forgotPassword') }}
            </VButton>
          </div>
        </div>
        <div class="w-full mx-auto">
          <VButton
            class="w-full"
            type="submit"
            :is-loading="isLoading"
            :disabled="!values.email && !values.password"
          >
            {{ $t('login.loginNow') }}
          </VButton>
        </div>

        <div>
          <div class="relative mt-2 mb-6">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-400" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 text-gray-400 bg-white">
                {{ $t('login.noAccountYet') }}
              </span>
            </div>
          </div>

          <VButton
            to="/signup"
            aria-label="Registrieren"
            class="w-full text-center"
            :variant="Variant.tertiary"
          >
            {{ $t('signUp') }}
          </VButton>
        </div>
      </form>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import * as Yup from 'yup'
import * as Sentry from '@sentry/vue'
import { useI18n } from 'vue-i18n'
import VButton from '@/components/VButton.vue'
import { Size, Variant } from '@/types/enums'

const supabase = useSupabaseClient()

const { t } = useI18n()
const isLoading = ref(false)
const isInvalidCredentials = ref(false)

Yup.setLocale({
  mixed: {
    required: t('validation.required'),
  },
  string: {
    email: t('validation.email'),
  },
})

const schema = Yup.object({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
})

const { values, validate } = useForm({
  validationSchema: schema,
  initialValues: {
    email: '',
    password: '',
  },
})

const handleLogin = async () => {
  try {
    validate()
    isLoading.value = true

    const { error } = await supabase.auth.signInWithPassword({
      email: values.email,
      password: values.password,
    })

    if (error) throw error

    useRouter().push(`/auth/welcome`)
  } catch (error) {
    if (
      error instanceof Error &&
      error.message !== 'Invalid login credentials'
    ) {
      Sentry.captureException(error)
    }

    isInvalidCredentials.value = true
  } finally {
    isLoading.value = false
  }
}
</script>
